<template>
  <v-app>
    <v-container style="background-color: #e1e3e9" fluid>
      <v-row>
        <v-col
          sm="12"
          cols="12"
          style="background-color: #fffff"
          class="ma-3 mt-4 pt-3 px-5"
        >
          <h2 class="text-uppercase mb-0">{{ $t("coming_soon") }}</h2>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  mounted() {},
  computed: {},
};
</script>
<style scoped>
@media (max-width: 576px) {
}
</style>
